<template>
    <section>
        <div class="section-inner" v-if="article">
            <div class="si-content">
                <div class="si-content-inner">
                    <div class="sci-left">
                        <div class="sl-title">
                            <h1>{{ article.title }}</h1>
                        </div>
                        <div class="sl-content">
                            <div class="sl-content-image">
                                <img :src="article.img_url" alt="rsia melinda"/>
                            </div>
                            <div class="sl-content-author">
                                <p>ditulis oleh :</p>
                                <h2>{{ article.author }}</h2>
                            </div>
                            <div class="sl-content-text" v-html="article.description"></div>
                        </div>
                    </div>
                    <div class="sci-right">
                        <div class="sr-jobs">
                            <div class="sr-jobs-link">
                                <router-link to="/articles">
                                    <span>Browse Other Articles</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="sr-categories">
                            <div class="sr-categories-head">
                                <h2>Categories</h2>
                            </div>
                            <div class="sr-categories-body" v-if="categories">
                                <div class="scb-part" v-for="category in categories" :key="category">
                                    <router-link :to="'/articles/category/' + category.slug">
                                        <span>{{ category.name }}</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ArticleDetailSkeleton v-else/>
    </section>
</template>

<script setup>
    import ArticleDetailSkeleton from '@/components/skeletons/sections/ArticleDetailSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useRoute } from 'vue-router';
    import { useArticleStore } from '@/stores/article';
    import { onUnmounted } from 'vue';
    const article = computed(() => useArticleStore().articleDetail);
    const categories = computed(() => useArticleStore().categories);
    useArticleStore().setArticleDetail(useRoute().params.slug);
    onUnmounted(() => {
        if(document.querySelector('link[rel="canonical"]')){
            document.querySelector('link[rel="canonical"]').remove();
        }
    });
</script>

<style scoped>
    section{
        transition: 0.2s;
        padding: 0 0 2.5rem 0;
        background: var(--cream-bg);
    }
    .section-inner{
        width: 100%;
        height: 100%;
    }
    .si-head{
        width: 100%;
        padding: 0 10% 0 10%;
    }
    .sh-inner{
        width: 100%;
        text-align: left;
        color: var(--white);
    }
    .sh-inner h2{
        margin: 0;
        font-size: 1.125rem;
        color: var(--cream);
    }
    .sh-inner h1{
        font-family: poppins;
        font-size: 3.25rem;
        margin: 0;
    }
    .sh-inner p{
        margin: 0;
    }
    .si-content{
        padding: 2.5rem 9% 0 9%;
        margin: 0 0 0 0;
        transition: 0.2s;
    }
    .si-content-inner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .sci-left{
        width: 70%;
    }
    .sl-title h1{
        font-size: 2rem;
        margin: 0 0 1rem 0;
        color: var(--navy);
    }
    .sl-content{
        color: var(--grey);
        text-align: justify;
    }
    .sl-content-image img{
        width: 100%;
    }
    .sl-content-author{
        color: var(--navy);
        border-bottom: 1px solid var(--softgrey);
    }
    .sl-content-author p{
        margin: 0.5rem 0 0 0;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--grey);
        line-height: 1;
    }
    .sl-content-author h2{
        font-size: 1.1rem;
        margin: 0.5rem 0 1rem 0;
        line-height: 1;
    }
    .sl-content-text a{
        color: var(--grey) !important;
        text-decoration: none;
    }
    .sci-right{
        width: 30%;
        padding: 0 0 1rem 1rem;
    }
    .sr-jobs{
        width: 100%;
        background: var(--primary);
        color: var(--white);
        padding: 1rem;
    }
    .sr-jobs h2{
        font-size: 1rem;
        margin: 0;
    }
    .sr-jobs-link{
        padding: 1rem 0 1rem 0;
    }
    .sr-jobs-link a{
        text-decoration: none;
        color: var(--grey);
        background: var(--cream);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 5rem;
        white-space: nowrap;
        font-size: 0.9rem;
    }
    .sr-categories{
        padding: 2.5rem 0 0 0;
    }
    .sr-categories-head h2{
        margin: 0;
        color: var(--navy);
        font-family: poppins;
        font-size: 1.1rem;
    }
    .scb-part{
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid var(--grey);
        font-size: 1rem;
        font-weight: 500;
    }
    .scb-part a{
        text-decoration: none;
        color: var(--grey);
    }
    .sr-tags{
        padding: 2.5rem 0 0 0;
    }
    .ser-tags-head h2{
        margin: 0;
        color: var(--navy);
        font-family: poppins;
        font-size: 1.1rem;
    }
    .sr-tags-body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .stb-part{
        width: auto;
        padding: 1rem 0.5rem 1rem 0.5rem;
        font-size: 0.9rem;
    }
    .stb-part a{
        background: var(--cream);
        color: var(--grey);
        padding: 0.5rem 1rem 0.5rem 1rem;
        white-space: nowrap;
        border-radius: 5rem;
        text-decoration: none;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 0 0 2.5rem 0;
        }
        .si-head{
            padding: 0 5% 0 5%;
        }
        .si-content{
            padding: 2.5rem 5% 0 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 0 0 2.5rem 0;
        }
        .si-head{
            padding: 0 2.5% 0 2.5%;
        }
        .sh-inner h1{
            font-size: 2rem;
        }
        .si-content{
            padding: 1rem 1.5% 0 1.5%;
        }
        .sci-left{
            width: 100%;
        }
        .sci-right{
            width: 100%;
            padding: 0 0 0 0;
        }
    }
    @media only screen and (max-width: 450px){
        .sh-inner h1{
            font-size: 1.5rem;
        }
    }
</style>