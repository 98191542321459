<template>
    <section>
        <div class="section-inner" v-if="service">
            <div class="si-content si-content-top">
                <div class="si-text">
                    <div class="cov-strip">
                        <div class="strip"></div>
                    </div>
                    <h2>Our Great Services</h2>
                    <h1>{{ service.name }}</h1>
                </div>
                <div class="si-picture">
                    <div class="si-picture-img">
                        <img :src="service.img_url" alt="rs melinda 3"/>
                    </div>
                </div>
            </div>
            <div class="si-content">
                <div class="sc-description">
                    <div v-html="service.description"></div>
                </div>
                <!-- <div class="sc-footer">
                    <h2>{{ service.footer.text_heading }}</h2>
                    <p>{{ service.footer.text_paragraf }}</p>
                    <div class="sc-footer-link">
                        <router-link to="/services">
                            <span>Back to Services Page</span>
                        </router-link>
                    </div>
                </div> -->
            </div>
        </div>
        <ServiceDetailSkeleton v-else/>
    </section>
</template>

<script setup>
    import ServiceDetailSkeleton from '@/components/skeletons/sections/ServiceDetailSkeleton.vue';
    import { useRoute } from 'vue-router';
    import { computed } from '@vue/reactivity';
    import { useServiceStore } from '@/stores/service';
    const service = computed(() => useServiceStore().serviceDetail);
    useServiceStore().setServiceDetail(useRoute().params.slug);
</script>

<style scoped>
    section{
        padding: 0 0 2.5rem 0;
        transition: 0.2s;
        /* background: var(--cream-bg); */
    }
    .si-content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 10% 0 10%;
        color: var(--grey);
        transition: 0.2s;
    }
    .si-content-top{
        background: var(--navy);
    }
    .cov-strip{
        display: flex;
        justify-content: left;
        padding: 1rem 0 0 0;
        transition: 0.2s;
    }
    .strip{
        width: 5%;
        height: 0.5rem;
        background: var(--red);
        transition: 0.2s;
    }
    .si-text{
        width: 50%;
        padding: 2.5rem 1rem 0 0;
        color: var(--white);
    }
    .si-text h2{
        font-size: 1.1rem;
        color: var(--primary);
        margin: 1rem 0 0 0;
    }
    .si-text h1{
        font-size: 2rem;
        margin: 1rem 0 1rem 0;
        transition: 0.2s;
    }
    .si-picture{
        width: 50%;
    }
    .si-picture-img{
        width: 100%;
    }
    .si-picture-img img{
        width: 100%;
    }
    .sc-description{
        width: 100%;
        padding: 2.5rem 0 0 0;
    }
    .sc-footer h2{
        font-size: 1.5rem;
        color: var(--navy);
        margin: 0;
        transition: 0.2s;
    }
    .sc-footer-link{
        padding: 1rem 0 1rem 0;
        user-select: none;
    }
    .sc-footer-link a{
        color: var(--grey);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border: 1px solid var(--grey);
        border-radius: 5rem;
        text-decoration: none;
        transition: 0.2s;
    }
    .sc-footer-link a:hover{
        background: var(--grey);
        color: var(--white);
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 0 0 2.5rem 0;
        }
        .si-content{
            padding: 0 5% 0 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 0 0 2.5rem 0;
        }
        .si-content{
            padding: 0 2.5% 0 2.5%;
        }
        .si-picture{
            width: 100%;
        }
        .si-text{
            width: 100%;
            padding: 0 0 0 0;
        }
        .si-text h1{
            font-size: 1.5rem;
        }
        .sc-footer h2{
            font-size: 1.1rem;
        }
    }
</style>