import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { galleryLayout } from "@/data/layouts.js";
import { createSlug } from "@/functions/general.js";

export const useGalleryStore = defineStore("gallery", {
    state: () => ({
        // ==================== states used on gallery page and its relation
        head: null,
        body: null,
        layouts: null,
        galleryDetailHead: null,
        galleryDetail: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on gallery page and its relation
        async setGallery(){
            this.layouts = galleryLayout;
            if(!this.head){
                this.setHead();
            }
            if(!this.body){
                this.setBody();
            }
            console.log('hello');
        },
        async setHead(){
            try{
                const response = await axiosClient.get(`page-header/gallery`);
                this.head = response.data.page_header;
                // console.log(this.head);
            }catch(error){
                console.log(error);
            }
        },
        async setBody(){
            try{
                const response = await axiosClient.get(`art`);
                this.body = response.data.arts;
                for(var i = 0; i < this.body.length; i++){
                    this.body[i].slug = createSlug(this.body[i].title);
                }
                // console.log(this.body);
            }catch(error){
                console.log(error);
            }
        },
        async setGalleryDetail(slug){
            if(!this.body){
                await this.setBody();
            }
            this.galleryDetailHead = null;
            this.galleryDetail = null;
            var galleryId;
            for(var i = 0; i < this.body.length; i++){
                if(this.body[i].slug == slug){
                    galleryId = this.body[i].art_id;
                }
            }
            // console.log(slug);
            try{
                // var galleryId = '4cde093b-e948-4729-8fa0-1654113cb92a';
                const response = await axiosClient.get(`art/` + galleryId);
                this.galleryDetailHead = {sub_title: 'RSIA Melinda', title: response.data.art.title, description: response.data.art.description};
                this.galleryDetail = response.data.art;
                // console.log(this.galleryDetail);
                // console.log(response.data);
            }catch(error){
                console.log(error);
            }
        }
    }
});