import { defineStore } from "pinia";
import backend1Axios from "../backend1.js";
import { createSlug, setPageData, wordsLimit, removeHTML } from "@/functions/general.js";

export const useArticleStore = defineStore("article", {
    state: () => ({
        // ==================== states used on articles page and its relation
        allArticles: null,
        articles: null,
        searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        categories: null,
        articlesShow: null,
        articleDetail: null,
        articlesByCategory: null,
        abcShow: null,
        header: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on articles page and its relation
        async setArticles(){
            if(!this.allArticles){
                try{
                    const response = await backend1Axios.get(`article`);
                    const header = await backend1Axios.get(`page-header/article`);
                    this.allArticles = response.data.articles;
                    this.articles = response.data.articles;
                    this.categories = response.data.categories;
                    this.header = header.data.page_header;
                    for(var i = 0; i < this.categories.length; i++){
                        this.categories[i].slug = createSlug(this.categories[i].name);
                    }
                    this.showArticles(6);
                    console.log(header);
                }catch(error){
                    console.log(error);
                }
            }
        },
        async setArticleDetail(slug){
            if(!this.allArticles){
                await this.setArticles();
            }
            this.articleDetail = null;
            try{
                const response = await backend1Axios.get(`article/` + slug);
                this.articleDetail = response.data.articles;
                setPageData({
                    title: this.articleDetail.title,
                    description: wordsLimit(removeHTML(this.articleDetail.description), 200),
                    canonical: 'https://melindahospital.com/artikel/' + this.articleDetail.slug
                });
                // console.log(this.articleDetail);
            }catch(error){
                console.log(error);
            }
        },
        async setArticlesByCategory(slug){
            if(!this.allArticles){
                await this.setArticles();
            }
            var ct = null;
            var at = [];
            for(var i = 0; i < this.categories.length; i++){
                if(this.categories[i].slug == slug){
                    ct = this.categories[i].name;
                }
            }
            for(i = 0; i < this.allArticles.length; i++){
                if(this.allArticles[i].category){
                    if(this.allArticles[i].category.name == ct){
                        at.push(this.allArticles[i]);
                    }
                }
            }
            this.articlesByCategory = at;
            this.showAbc(6);
            setPageData({
                title: 'Artikel Tentang ' + ct,
                description: 'Artikel Tentang ' + ct
            });
        },
        showArticles(limit){
            this.articlesShow = this.articles.slice(0, limit);
        },
        moreArticles(){
            const ln = this.articlesShow.length;
            this.showArticles((ln + 6));
        },
        searchbarSelect(category, slug){
            this.searchBar.select.title = category;
            this.searchBar.select.value = slug;
            this.searchService();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchService();
        },
        searchService(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ // if selected specialist
                for(var i = 0; i < this.allArticles.length; i++){
                    if(this.allArticles[i].category){
                        if(this.allArticles[i].category.name == this.searchBar.select.title){
                            newsd.push(this.allArticles[i]);
                        }
                    }
                }
            }else{
                newsd = this.allArticles;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    if(newsd[i].title.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.articles = result;
            }else{
                this.articles = newsd;
            }
            this.showArticles(6);
        },
        showAbc(limit){
            this.abcShow = this.articlesByCategory.slice(0, limit);
        },
        moreAbc(){
            const ln = this.abcShow.length;
            this.showAbc((ln + 6));
        }
    }
});