<template>
    <VideoComponent/>
    <!-- <VideoHeaderComponent/> -->
    <!-- <VideoCardComponent/> -->
    <!-- <FooterContent/> -->
</template>
<script setup>
    import VideoComponent from '@/components/video/VideoComponent.vue';
    // import VideoHeaderComponent from '@/components/video/VideoHeaderComponent.vue';
    // import VideoCardComponent from '@/components/video/VideoCardComponent.vue';
    // import FooterContent from '@/components/utils/FooterContent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Video RS Melinda 2 Bandung',
            description: 'Halaman yang memberikan kemudahan akses untuk mendapatkan video mengenai kesehatan, gaya hidup, dan informasi menarik lainnya dan tentunya informasi terbaru mengenai RS Melinda 2.'
        });
    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>
<style>
    /*  */
</style>