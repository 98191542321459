<template>
    <section>
        <div class="section-content">
            <!-- <GoogleMap api-key="" style="width: 100%; height: 500px" :center="center" :zoom="15">
                <Marker :options="{ position: center }" />
            </GoogleMap> -->
            <iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=Jl.%20Pajajaran%20No.46,%20Pasir%20Kaliki,%20Kec.%20Cicendo,%20Kota%20Bandung,%20Jawa%20Barat%2040171&t=&z=19&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
    </section>
</template>

<script>
    // import { defineComponent } from "vue";
    // import { GoogleMap, Marker } from "vue3-google-map";

    // export default defineComponent({
    // components: { GoogleMap, Marker },
    // setup() {
    //     const center = { lat: -6.9070377, lng: 107.6031955 };

    //     return { center };
    // }
    // });
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #ffffff;
    }
    .section-header{
        text-align: left;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        margin-top: 2rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    iframe{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 710px){
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
    }
</style>