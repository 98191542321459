<template>
    <section>
        <div class="section-inner" v-if="contact">
            <div class="hotline">
                <div class="si-icon">
                    <img src="../../assets/images/icons/phone-hollow.png" alt="rs melinda 2"/>
                </div>
                <div class="si-text">
                    <div class="si-text-param">
                        <span>Hotline & Emergency Case</span>
                    </div>
                    <div class="si-text-value">
                        <span>{{ contact.contact_us.phone_number }}</span>
                    </div>
                </div>
            </div>
            <div class="opening">
                <div class="si-icon">
                    <img src="../../assets/images/icons/clock-hollow.png" alt="rs melinda 2"/>
                </div>
                <div class="si-text">
                    <div class="si-text-param">
                        <span>Hotline & Emergency Case</span>
                    </div>
                    <div class="si-text-value">
                        <span>24 Hours</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useContactStore } from '@/stores/contact';
    // import { removeHTML, wordsLimit } from '@/functions/general';
    const contact = computed(() => useContactStore().contactData);
    useContactStore().setContact();
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        background: var(--navy);
        color: var(--white);
    }
    section h3{
        font-size: 1.1rem;
        color: var(--primary);
    }
    section h2{
        font-size: 1.5rem;
        color: var(--white);
    }
    .section-inner{
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
    }
    .hotline{
        width: 50%;
        padding: 0 1rem 0 0;
        display: flex;
    }
    .si-text{
        padding: 0 0 0 1rem;
    }
    .si-text-param{
        font-size: 1.1rem;
        font-weight: 700;
    }
    .si-text-value{
        padding: 0.5rem 0 0.5rem 0;
    }
    .opening{
        width: 50%;
        padding: 0 0 0 1rem;
        display: flex;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .hotline{
            width: 100%;
            padding: 0;
        }
        .opening{
            width: 100%;
            padding: 0;
        }
    }
</style>