<template>
    <section>
        <div class="section-inner">
            <div class="si-header">
                <div class="sh-inner" v-if="header">
                    <h2>{{ header.sub_title }}</h2>
                    <h1>{{ header.title }}</h1>
                    <div>
                        <p>{{ wordsLimit(removeHTML(header.description), 750) }}</p>
                    </div>
                </div>
                <DoctorHeaderSkeleton v-else/>
                <div class="si-box">
                    <div class="si-search" v-if="splist">
                        <div class="si-search-select">
                            <div class="sss-button" id="sss-button-dss" @click="topSearchSelect('sss-button-dss');">
                                <div class="button-cover float-button"></div>
                                <div class="sss-text">
                                    <div class="sss-text-title">Spesialis</div>
                                    <div class="sss-text-button">{{ searchBar.select.title }}</div>
                                </div>
                                <div class="sss-icon">
                                    <div class="sss-icon-inner float-arrow">
                                        <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                    </div>
                                </div>
                            </div>
                            <div class="sss-option float">
                                <div class="sss-option-button" @click="useDoctorStore().sbSelect('Semua Dokter', 'all');">
                                    <span>Semua Dokter</span>
                                </div>
                                <div class="sss-option-button" @click="useDoctorStore().sbSelect(sp.name, sp.slug);" v-for="sp in splist" :key="sp">
                                    <span>{{ sp.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="si-search-input">
                            <input type="text" id="sss-input-dss" :value="searchBar.input.value" placeholder="masukkan nama dokter" autocomplete="off" @input="sbInput('sss-input-dss');" />
                        </div>
                        <div class="si-search-button">
                            <span>Search Doctor & Schedule</span>
                        </div>
                    </div>
                    <DoctorSearchbarSkeleton v-else/>
                </div>
            </div>
            
            <div class="si-doctor" v-if="specialists">
                <div class="si-doctor-list" v-for="(specialist, si) in specialists" :key="specialist">
                    <div class="sdl-category">
                        <h2>Melinda Hospital Doctors</h2>
                        <h3>{{ specialist.name }}</h3>
                    </div>
                    <div class="sdl-detail">
                        <div class="sdl-detail-part" v-for="(doctor, di) in specialist.doctors" :key="doctor">
                            <div class="sdp-inner">
                                <div class="sdp-image">
                                    <img :src="doctor.self_img_url" alt="rsia melinda"/>
                                </div>
                                <div class="sdp-detail">
                                    <h4>{{ doctor.name }}</h4>
                                    <button @click="showModal('sdp-modal-' + si + '-' + di); useDoctorStore().setDoctorInfo(doctor.doctor_id);">Lihat Jadwal</button>
                                    <div class="modal sdp-modal" :id="'sdp-modal-' + si + '-' + di">
                                        <div class="smc-close" @click="hideModal('sdp-modal-' + si + '-' + di);">
                                            <img src="../../assets/images/icons/navytimes.png" alt="rsia melinda"/>
                                        </div>
                                        <div class="sdp-modal-content">
                                            <div class="smc-image">
                                                <img :src="doctor.self_img_url" alt="rsia melinda"/>
                                            </div>
                                            <div class="smc-detail">
                                                <div class="smc-detail-main">
                                                    <div class="sdm-inner">
                                                        <div class="smc-label">
                                                            <span>{{ specialist.name }}</span>
                                                        </div>
                                                        <div class="smc-name">
                                                            <h5>{{ doctor.name }}</h5>
                                                        </div>
                                                        <div class="smc-description" v-if="doctor.about">
                                                            <div v-html="doctor.about"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="smc-detail-additional">
                                                    <div class="smc-schedule">
                                                        <h6>Jadwal</h6>
                                                        <div class="smc-schedule-column">
                                                            <div class="ssc-head">
                                                                <div class="ssc-row">
                                                                    <div class="ssc-row-part">
                                                                        <span>Hari</span>
                                                                    </div>
                                                                    <div class="ssc-row-part">
                                                                        <span>Jam Praktek</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="ssc-body" v-if="doctor.days">
                                                                <div class="ssc-row" v-for="day in doctor.days" :key="day">
                                                                    <div class="ssc-row-part">
                                                                        <span>{{ day.name }}</span>
                                                                    </div>
                                                                    <div class="ssc-row-part">
                                                                        <template v-if="!day.appointment">
                                                                            <div class="srp-inner" v-for="sc in day.schedule" :key="sc">
                                                                                <span>{{ scheduleHour(sc.time_start) }} - {{ scheduleHour(sc.time_end) }}</span>
                                                                            </div>
                                                                        </template>
                                                                        <template v-else>
                                                                            <div class="srp-inner">
                                                                                <span class="srp-appointment">By Appointment</span>
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="smc-awards" v-if="doctor.awards">
                                                        <h6>Penghargaan</h6>
                                                        <div v-html="doctor.awards"></div>
                                                    </div>
                                                    <div class="smc-organizations" v-if="doctor.organizations">
                                                        <h6>Organisasi</h6>
                                                        <div v-html="doctor.organizations"></div>
                                                    </div>
                                                    <div class="smc-publications" v-if="doctor.publications">
                                                        <h6>Publikasi</h6>
                                                        <div v-html="doctor.publications"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DoctorBodySkeleton v-else/>
        </div>
    </section>
</template>

<script setup>
    import DoctorHeaderSkeleton from '@/components/skeletons/parts/DoctorHeaderSkeleton.vue';
    import DoctorSearchbarSkeleton from '@/components/skeletons/parts/DoctorSearchbarSkeleton.vue';
    import DoctorBodySkeleton from '@/components/skeletons/parts/DoctorBodySkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useDoctorStore } from '@/stores/doctor';
    import { topSearchSelect, showModal, hideModal, scheduleHour, removeHTML, wordsLimit } from '@/functions/general';
    import { sbInput } from '@/functions/doctor';
    const header = computed(() => useDoctorStore().header);
    const searchBar = computed(() => useDoctorStore().searchBar);
    const specialists = computed(() => useDoctorStore().specialists);
    const splist = computed(() => useDoctorStore().specialistList);
    useDoctorStore().setSpecialists();
</script>

<style scoped>
    section{
        transition: 0.2s;
    }
    .section-inner{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .si-header{
        position: relative;
        width: 100%;
        padding: 2.5rem 10% 5rem 10%;
        background: var(--navy);
    }
    .sh-inner{
        text-align: center;
    }
    .sh-inner h2{
        font-size: 1.1rem;
        color: var(--primary);
        margin: 0;
    }
    .sh-inner h1{
        font-size: 2rem;
        color: var(--white);
        margin: 1rem 0 1rem 0;
    }
    .sh-inner p{
        margin: 0;
        color: var(--white);
    }
    .si-box{
        position: absolute;
        left: 10%;
        right: 10%;
        bottom: -25px;
        transition: 0.2s;
    }
    .si-search{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 75px;
        transition: 0.2s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
    }
    .si-search-select{
        position: relative;
        width: 30%;
        height: 100%;
        user-select: none;
        background: var(--white);
        transition: 0.2s;
    }
    .sss-button{
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
    }
    .button-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .sss-text{
        width: 90%;
    }
    .sss-text-title{
        font-weight: 700;
        padding: 0 0.5rem 0 0.5rem;
    }
    .sss-text-button{
        padding: 0.5rem;
        font-size: 0.9rem;
    }
    .sss-icon{
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sss-icon-inner{
        text-align: center;
        width: 20px;
        height: 20px;
    }
    .sss-icon img{
        max-width: 100%;
        transition: 0.2s;
    }
    .sss-option{
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        background: var(--white);
        display: none;
        -webkit-animation-name: animatesssoption;
        -webkit-animation-duration: 0.4s;
        animation-name: animatesssoption;
        animation-duration: 0.4s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
        z-index: 5;
    }
    @-webkit-keyframes animatesssoption {
        from {top:150%; opacity:0}
        to {top:110%; opacity:1}
    }
    @keyframes animatesssoption {
        from {top:150%; opacity:0}
        to {top:110%; opacity:1}
    }
    .float-active{
        display: block;
    }
    .sss-option-button{
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .sss-option-button:hover{
        background: var(--smoke);
    }
    .si-search-input{
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid var(--softgrey);
        padding: 1rem;
        background: var(--white);
        transition: 0.2s;
    }
    .si-search-input input{
        border: none;
        outline: none;
        width: 100%;
        height: 50px;
        color: var(--grey);
        font-size: 1.2rem;
    }
    .si-search-button{
        width: 30%;
        height: 100%;
        background: var(--primary);
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
    }
    .si-doctor{
        padding: 5rem 10% 2.5rem 10%;
        min-height: 200px;
    }
    .si-doctor-list{
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 2.5rem 0;
    }
    .sdl-category{
        width: 25%;
        transition: 0.2s;
    }
    .sdl-category h2{
        font-size: 1.125rem;
        margin: 0;
        color: var(--primary);
        transition: 0.2s;
    }
    .sdl-category h3{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        margin: 1rem 0 1rem 0;
        color: var(--navy);
        line-height: 1;
        transition: 0.2s;
    }
    .sdl-detail{
        width: 75%;
        display: flex;
        flex-wrap: wrap;
    }
    .sdl-detail-part{
        width: 25%;
        height: 270px;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sdp-inner{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .sdp-image{
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: var(--navy);
    }
    .sdp-image img{
        width: 100%;
        object-fit: cover;
    }
    .sdp-detail{
        position: absolute;
        width: 100%;
        min-height: 75px;
        left: 0;
        bottom: 0;
        background: var(--navy);
        color: var(--white);
        padding: 0.5rem;
    }
    .sdp-detail h4{
        margin: 0;
    }
    .sdp-detail button{
        background: none;
        border: none;
        color: var(--cream);
        padding: 0;
        cursor: pointer;
    }
    @-webkit-keyframes animatesdp {
        from {top: -100%; opacity:0}
        to {top: 0; opacity:1}
    }
    @keyframes animatesdp {
        from {top: -100%; opacity:0}
        to {top: 0; opacity:1}
    }
    .sdp-modal{
        background: #00000071;
        padding: 2rem 10rem 2rem 10rem;
        transition: 0.2s;
        z-index: 50;
    }
    .sdp-modal-content{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        background: var(--white);
        color: var(--grey);
        animation-name: animatesdp;
        animation-duration: 0.5s;
        transition: 0.2s;
        overflow: auto;
        border-radius: 5px;
    }
    .smc-close{
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.2s;
        user-select: none;
        background: var(--white);
        padding: 0.5rem;
        border-radius: 5px;
        z-index: 1;
        opacity: 0.5;
    }
    .smc-close:hover{
        color: var(--primary);
    }
    .smc-close img{
        max-width: 75%;
    }
    .smc-image{
        width: 40%;
        transition: 0.2s;
    }
    .smc-image img{
        width: 100%;
    }
    .smc-detail{
        position: relative;
        width: 60%;
        height: 100%;
        padding: 1rem;
        transition: 0.2s;
    }
    .smc-detail-main{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40%;
        overflow: auto;
        background: var(--white);
        padding: 1rem;
        border-bottom: 1px solid var(--smoke);
    }
    .sdm-inner{
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        padding: 0.5rem;
    }
    .smc-label{
        font-size: 1rem;
        color: var(--primary);
    }
    .smc-name h5{
        margin: 0.5rem 0 1rem 0;
        font-size: 1.5rem;
        font-family: poppins;
        color: var(--navy);
        transition: 0.2s;
    }
    .smc-schedule{
        padding: 0 0.5rem 1rem 0;
    }
    .smc-schedule h6{
        margin: 0.5rem 0 1rem 0;
        font-size: 1.1rem;
        font-family: poppins;
        color: var(--navy);
        transition: 0.2s;
    }
    .smc-schedule-column{
        border: 1px solid var(--softgrey);
        padding: 1rem;
    }
    .ssc-head{
        color: #000000;
        font-size: 1rem;
        font-weight: 700;
    }
    .ssc-row{
        display: flex;
        width: 100%;
        padding: 0.5rem 0 0.5rem 0;
    }
    .ssc-row-part{
        width: 50%;
    }
    .srp-appointment{
        font-weight: 700;
    }
    .smc-description{
        max-height: 100px;
        overflow: auto;
    }
    .smc-detail-additional{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        min-height: 200px;
        height: 60%;
        overflow: auto;
        padding: 1rem;
    }
    .smc-awards{
        padding: 0 0 1rem 0;
    }
    .smc-awards h6{
        margin: 0.5rem 0 1rem 0;
        font-size: 1.1rem;
        font-family: poppins;
        color: var(--navy);
        transition: 0.2s;
    }
    .smc-organizations{
        padding: 0 0 1rem 0;
    }
    .smc-organizations h6{
        margin: 0.5rem 0 1rem 0;
        font-size: 1.1rem;
        font-family: poppins;
        color: var(--navy);
        transition: 0.2s;
    }
    .smc-publications{
        padding: 0 0 1rem 0;
    }
    .smc-publications h6{
        margin: 0.5rem 0 1rem 0;
        font-size: 1.1rem;
        font-family: poppins;
        color: var(--navy);
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        .si-header{
            padding: 2.5rem 5% 5rem 5%;
        }
        .si-box{
            left: 5%;
            right: 5%;
        }
        .si-doctor{
            padding: 5rem 5% 2.5rem 5%;
        }
        .sdl-detail-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 1270px){
        .si-header{
            padding: 2.5rem 5% 10rem 5%;
        }
        .si-search{
            height: auto;
            box-shadow: unset;
        }
        .si-search-select{
            width: 50%;
            height: 75px;
            border-top: 1px solid var(--softgrey);
            border-left: 1px solid var(--softgrey);
            border-bottom: 1px solid var(--softgrey);
        }
        .si-search-input{
            width: 50%;
            height: 75px;
            border-top: 1px solid var(--softgrey);
            border-right: 1px solid var(--softgrey);
            border-bottom: 1px solid var(--softgrey);
        }
        .si-search-button{
            width: 100%;
            height: 75px;
            margin-top: 1rem;
        }
        .sdl-detail-part{
            height: 250px;
        }
    }
    @media only screen and (max-width: 1200px){
        .sdl-category h3{
            font-size: 1.5rem;
        }
        .sdp-modal{
            padding: 1rem;
        }
    }
    @media only screen and (max-width: 750px){
        .si-header{
            padding: 2.5rem 2.5% 20rem 2.5%;
        }
        .si-header h2{
            font-size: 0.9rem;
        }
        .si-header h1{
            font-size: 2rem;
        }
        .si-box{
            left: 2.5%;
            right: 2.5%;
        }
        .si-search-select{
            width: 100%;
            border-right: 1px solid var(--softgrey);
        }
        .si-search-input{
            width: 100%;
            margin-top: 1rem;
            border: 1px solid var(--softgrey);
        }
        .si-search-button{
            width: 100%;
        }
        .si-doctor{
            padding: 5rem 2.5% 2.5rem 2.5%;
        }
        .sdl-category{
            width: 100%;
        }
        .sdl-detail{
            width: 100%;
        }
        .sdl-detail-part{
            width: 50%;
            height: 270px;
        }
        .sdp-modal{
            padding: 0;
        }
        .sdp-modal-content{
            border-radius: 0;
        }
        .smc-image{
            width: 100%;
        }
        .smc-detail{
            width: 100%;
        }
        .smc-name h5{
            font-size: 1.2rem;
        }
    }
    @media only screen and (max-width: 450px){
        .sdl-detail-part{
            width: 100%;
        }
    }
</style>