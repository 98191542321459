<template>
    <section>
        <div class="section-header">
            <div class="si-header" v-if="head">
                <h2>{{ head.sub_title }}</h2>
                <h1>{{ head.title }}</h1>
                <div v-html="head.description"></div>
            </div>
            <GalleryHeaderSkeleton v-else/>
        </div>
    </section>
</template>

<script setup>
    import GalleryHeaderSkeleton from '@/components/skeletons/parts/GalleryHeaderSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useGalleryStore } from '@/stores/gallery';
    const head = computed(() => useGalleryStore().head);
    // useGalleryStore().setGallery();
</script>

<style scoped>
    section{
        transition: 0.2s;
        padding: 2.5rem 10% 0 10%;
        background: var(--navy);
    }
    .section-inner{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .si-header{
        width: 100%;
        text-align: center;
        padding: 0 0 2.5rem 0;
        color: var(--white);
    }
    .si-header h2{
        margin: 0;
        font-size: 1.125rem;
        color: var(--primary);
        transition: 0.2s;
    }
    .si-header h1{
        font-family: poppins;
        font-size: 2rem;
        margin: 1rem 0 1rem 0;
        transition: 0.2s;
        color: var(--white);
    }
    .si-header p{
        margin: 0;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 0 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 0 2.5%;
        }
    }
</style>