import { usePictureStore } from "@/stores/picture";
export function searchbarInput(id){
    usePictureStore().searchbarInput(document.getElementById(id).value);
}










