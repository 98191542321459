<template>
    <HomeServiceListComponent/>
    <HomeServiceCarouselComponent v-if="banner"/>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import HomeServiceListComponent from '@/components/home/HomeServiceListComponent.vue';
    import HomeServiceCarouselComponent from '@/components/home/HomeServiceCarouselComponent.vue';
    import { useServiceStore } from '@/stores/service';
    const banner = computed(() => useServiceStore().serviceList);
    useServiceStore().setServices();
</script>

<style scoped>
    /*  */
</style>

