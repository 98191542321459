import { useServiceStore } from "@/stores/service";
export function searchbarInput(id){
    useServiceStore().searchbarInput(document.getElementById(id).value);
}










