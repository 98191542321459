<template>
    <section>
        <div class="section-inner">
            <div class="si-left">
                <h3>Stay strong and lets beat Cervical Cancer!</h3>
                <h2>Our Great Services for Your Family Care</h2>
                <div class="si-left-button">
                    <router-link to="/services">
                        <span>View All Services</span>
                    </router-link>
                </div>
            </div>
            <div class="si-right">
                <div class="si-right-inner" v-if="services">
                    <template v-for="(service, index) in services" :key="service">
                        <div class="si-right-list" v-if="index < 10">
                            <router-link :to="'/services/detail/' + service.slug">
                                <span>{{ service.name }}</span>
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useServiceStore } from '@/stores/service';
    const services = computed(() => useServiceStore().serviceList);
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        transition: 0.2s;
    }
    .section-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .si-left{
        width: 50%;
        transition: 0.2s;
    }
    .si-left h3{
        font-size: 1.1rem;
        margin: 0;
        color: var(--primary);
    }
    .si-left h2{
        font-size: 1.5rem;
        margin: 1rem 0 1rem 0;
        color: var(--grey);
    }
    .si-left-button{
        padding: 1rem 0 1rem 0;
    }
    .si-left-button a{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 5rem;
    }
    .si-right{
        width: 50%;
        transition: 0.2s;
    }
    .si-right-inner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .si-right-list{
        width: 50%;
        padding: 0 0 1rem 0;
        transition: 0.2s;
    }
    .si-right-list a{
        color: var(--navy);
        font-size: 1.1rem;
        font-weight: 700;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .si-left{
            width: 100%;
        }
        .si-right{
            width: 100%;
            padding: 2.5rem 0 0 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
    }
    @media only screen and (max-width: 500px){
        .si-right-list{
            width: 100%;
        }
        .si-right-list a{
            font-size: 1rem;
        }
    }
</style>