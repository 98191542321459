<template>
    <LinkTopComponent/>
    <LinkBottomComponent/>
</template>

<script setup>
    import LinkTopComponent from '@/components/link/LinkTopComponent.vue';
    import LinkBottomComponent from '@/components/link/LinkBottomComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Grup RS Melinda',
            description: 'Tetap ikuti perkembangan kami, dengan klik link tautan di bawah ini untuk mengetahui informasi dan layanan yang kami sediakan.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>