export const docsort = [
    {
        id: 0,
        name: 'dr. Susan Melinda, Sp.OG',
        value: 100
    },
    {
        id: 1,
        name: 'Dr. dr. H. Rono.S.Hanny, Sp.OG(K), MM',
        value: 91
    },
    {
        id: 2,
        name: 'Anthony Sudono Riyadi, dr., Sp.OG, M.Sc., DMAS',
        value: 90
    },
    {
        id: 3,
        name: 'Monica Christiana, dr., Sp.OG',
        value: 89
    },
    {
        id: 4,
        name: 'dr. Hovlanta Pidingan, Sp.OG',
        value: 88
    },
    {
        id: 5,
        name: 'Julius Tornado Pangajoman, dr., Sp.OG',
        value: 87
    },
    {
        id: 6,
        name: 'dr. Ryandra Prakasa Tryastama, Sp.OG',
        value: 86
    },
    {
        id: 7,
        name: 'dr. Ayu Angelina Ronosulistyo, SpOG',
        value: 85
    },
    {
        id: 8,
        name: 'dr. Erich Chandra Sianipar, SpOG',
        value: 84
    },
    {
        id: 9,
        name: 'dr. Shinta Lestari, Sp.OG',
        value: 83
    },
    {
        id: 10,
        name: 'dr. Henry Kristian D. Silaen, M.Ked(OG), Sp.OG',
        value: 82
    },
    {
        id: 11,
        name: 'dr. Robert Hendrik S. Sp.OG',
        value: 1
    },
    {
        id: 12,
        name: 'Dr. Tetty Yuniati, dr., Sp.A(K)., M.Kes',
        value: 90
    },
    {
        id: 13,
        name: 'dr. Yulia Suryakusuma, Sp.A, M.Kes',
        value: 89
    },
    {
        id: 14,
        name: 'dr. Adri Zamany Anwary, Sp.A',
        value: 88
    },
    {
        id: 15,
        name: 'dr. Dono Pranoto, Sp.B, M.Kes',
        value: 90
    },
    {
        id: 16,
        name: 'Esti Sunyaruri, drg., Sp.KGA',
        value: 90
    },
    {
        id: 17,
        name: 'drg. Assandy',
        value: 89
    },
    {
        id: 18,
        name: 'Dr. drg. Yanti R. Sp.Perio(K)',
        value: 88
    },
    {
        id: 19,
        name: 'dr. Yovita Limiawan, Sp.PD, FPCP',
        value: 90
    },
    {
        id: 20,
        name: 'Prof. Dr. Tatang Bisri, dr., Sp.An., KNA., KAO',
        value: 90
    },
    {
        id: 21,
        name: 'Dr. Dewi Yulianti Bisri, dr., Sp.An., KNA., KAO., M.Kes',
        value: 89
    },
    {
        id: 22,
        name: 'dr. Army Zaka Anwary, Sp.An',
        value: 88
    },
    {
        id: 23,
        name: 'dr. Stephanie Supriadi, Sp.A',
        value: 75
    },
    {
        id: 23,
        name: 'dr. Zia Ulfa Kamaluddien',
        value: 90
    },
    {
        id: 23,
        name: 'dr. Roy Christian',
        value: 75
    },
    {
        id: 23,
        name: 'dr. Annastasia Mia Novita',
        value: 75
    },
];