<template>
    <section>
        <div class="section-header">
            <div class="si-header" v-if="head">
                <h2>{{ head.sub_title }}</h2>
                <h1>{{ head.title }}</h1>
                <div v-html="head.description"></div>
            </div>
            <LinkHeaderSkeleton v-else/>
        </div>
        <div class="section-body" v-if="body">
            <div class="sb-part">
                <div class="sb-part-image">
                    <img :src="body.melinda2.img_url" alt="rsia melinda"/>
                </div>
                <div class="sb-part-detail spd-left">
                    <div class="spd-label">
                        <span>Group of Melinda Hospital</span>
                    </div>
                    <div class="spd-title">
                        <h2>{{ body.melinda2.name }}</h2>
                    </div>
                    <div class="spd-description">
                        <div v-html="body.melinda2.description"></div>
                    </div>
                    <div class="spd-link">
                        <a :href="body.melinda2.link" target="_blank">
                            <span>Link to Website</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="sb-part">
                <div class="sb-part-image">
                    <img :src="body.melinda3.img_url" alt="rsia melinda"/>
                </div>
                <div class="sb-part-detail spd-right">
                    <div class="spd-label">
                        <span>Group of Melinda Hospital</span>
                    </div>
                    <div class="spd-title">
                        <h2>{{ body.melinda3.name }}</h2>
                    </div>
                    <div class="spd-description">
                        <div v-html="body.melinda3.description"></div>
                    </div>
                    <div class="spd-link">
                        <a :href="body.melinda3.link" target="_blank">
                            <span>Link to Website</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <LinkBodySkeleton v-else/>
    </section>
</template>

<script setup>
    import LinkHeaderSkeleton from '@/components/skeletons/parts/LinkHeaderSkeleton.vue';
    import LinkBodySkeleton from '@/components/skeletons/parts/LinkBodySkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useLinkStore } from '@/stores/link';
    const head = computed(() => useLinkStore().head);
    const body = computed(() => useLinkStore().body);
    useLinkStore().setLink();
</script>

<style scoped>
    section{
        transition: 0.2s;
        padding: 2.5rem 10% 0 10%;
    }
    .section-inner{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .si-header{
        width: 100%;
        text-align: center;
        padding: 0 0 2.5rem 0;
        color: var(--grey);
    }
    .si-header h2{
        margin: 0;
        font-size: 1.125rem;
        color: var(--primary);
        transition: 0.2s;
    }
    .si-header h1{
        font-family: poppins;
        font-size: 2rem;
        margin: 1rem 0 1rem 0;
        transition: 0.2s;
        color: var(--navy);
    }
    .si-header p{
        margin: 0;
    }
    .section-body{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background: var(--white);
        transition: 0.2s;
    }
    .sb-part{
        position: relative;
        width: 50%;
        min-height: 200px;
        transition: 0.2s;
    }
    .sb-part-image{
        width: 100%;
        z-index: 1;
        overflow: hidden;
    }
    .sb-part-image img{
        width: 100%;
        transition: 0.2s;
    }
    .sb-part-detail{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 5;
    }
    .spd-left{
        padding: 1rem 1rem 1rem 1rem;
        transition: 0.2s;
    }
    .spd-label{
        /* padding: 1rem 0 1rem 0; */
        color: var(--primary);
        font-size: 1.1rem;
        font-weight: 600;
    }
    .spd-title h2{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        margin: 0.5rem 0 0.5rem 0;
        color: var(--navy);
        line-height: 1;
        transition: 0.2s;
    }
    .spd-left .spd-description{
        color: var(--grey);
    }
    .spd-right .spd-title h2{
        color: var(--white);
        background: #00000077;
    }
    .spd-right .spd-description{
        color: var(--white);
        background: #00000077;
    }
    .spd-link{
        padding: 2rem 0 1rem 0;
    }
    .spd-link a{
        text-decoration: none;
        color: var(--grey);
        background: var(--cream-bg);
        padding: 0.5rem 2rem 0.5rem 2rem;
        border-radius: 5rem;
        font-size: 1rem;
        font-weight: 600;
        user-select: none;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
    }
    .spd-link a:hover{
        text-decoration: underline;
    }
    .spd-right{
        padding: 1rem 1rem 1rem 1rem;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 0 5%;
        }
    }
    @media only screen and (max-width: 1200px){
        .sb-part{
            width: 100%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 0 2.5%;
        }
        .sb-part-image img{
            width: unset;
            min-height: 370px;
            height: 100%;
            max-height: 450px;
        }
        .spd-label{
            font-size: 0.9rem;
        }
        .spd-title h2{
            font-size: 1.5rem;
        }
    }
    @media only screen and (max-width: 450px){
        .sb-part-image img{
            min-height: 200px;
            max-height: 310px;
        }
    }
    @media only screen and (max-width: 310px){
        .sb-part-image img{
            min-height: 150px;
            max-height: 270px;
        }
    }
</style>