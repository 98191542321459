<template>
    <ArticleDetailComponent/>
</template>

<script setup>
    import ArticleDetailComponent from '@/components/articles/ArticleDetailComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>