<template>
    <section>
        <div class="section-header"></div>
        <div class="section-body">
            <!-- {{ video }} -->
            <div class="section-body-video" id="section-body-video">
                <div class="sbv-inner" v-if="video">
                    <div class="sbv-video">
                        <video :width="videoWidth" autoplay controls v-if="video.type == 'internal'">
                            <source :src="video.video">
                            <!-- <source src="mov_bbb.ogg" type="video/ogg"> -->
                            Your browser does not support HTML video.
                        </video>
                        <iframe :src="video.video" width="100%" title="Whole Bunch Of Juicy Shrimp With Lemon! The Perfect Snack For An Evening Rest" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-else></iframe>
                    </div>
                    <div class="sbv-detail">
                        <div class="sbv-title">
                            <h1>{{ video.name }}</h1>
                        </div>
                        <div class="sbv-description">
                            <div v-html="video.description"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-body-related">
                <!-- <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="../../assets/images/hq720.webp" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/vi/gDD1EKR9_fg/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAdjVGn7cgej65EFEaRPyg2ycLa3A" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/vi/dDI8iSnsnms/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCcQeMOjmXuZL8Sgpj4gGHSSn9Pog" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/vi/K-Ib04nqtr4/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBak6v-2-0rx8O1fQBuuGzsSROY9w" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/vi/9Hxjya1GgdI/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCaXbRNkD-bdi2HLE-25E68sFn9JA" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/vi/tNg1ZrWcvKg/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDyMx-iJsyYuliOp3PI8iEHMb4jPw" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/an_webp/UQdmNHRqXsw/mqdefault_6s.webp?du=3000&sqp=CLijqpkG&rs=AOn4CLCBIbD6CZ-jCnEpxghYwwgkr_1gTQ" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/vi/9LWmM-3lb84/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDzpMtyCpYx-vdM-ntJPM-ZJms4NQ" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/vi/Dngsi8X6wqc/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAO3trM01BPaZNZKI-6y25F5XfpcA" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div>
                <div class="sbr-part">
                    <div class="sbr-part-img">
                        <img src="https://i.ytimg.com/an_webp/dnzxKYr5ZFc/mqdefault_6s.webp?du=3000&sqp=CM7BqpkG&rs=AOn4CLBrEDjSj6XmHhAHWpUruBrYsAVnOg" alt="rumah sakit melinda 2" />
                    </div>
                    <div class="sbr-part-detail">
                        <p>Related text</p>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <div class="section-body" v-if="slug == 'orthopaedi-traumatologi'">
            <div class="section-body-video">
                <div class="sbv-video">
                    <video width="100%" autoplay controls>
                        <source src="https://backend2.melindadev.com/videos/BSSE.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
                <div class="sbv-detail">
                    <div class="sbv-title">
                        <h1>Biportal Endoscopic Spine Surgery</h1>
                    </div>
                    <div class="sbv-description">
                        <p>Biportal Endoscopic Spine Surgery merupakan prosedur bedah minimal invasif / minim luka di tubuh untuk menangani tulang belakang yang mengakibatkan urat saraf kejepit.
luka yang diakibatkan oleh bedah ini sangat kecil kurang lebih berukuran 0,5 cm sebanyak 2 buah. RS Melinda memiliki dokter spesialis orthopaedi & traumatologi tulang belakang yaitu Dr Ahmad Ramdan, dr., Sp. OT(K)., MKM selain itu RS Melinda 2 dilengkapi dengan alat-alat yang canggih yaitu kamera dan alat atau probe untuk pengangkatan atau pembebasan urat saraf terjepit.
kelebihan dari metodi ini adalah proses pemulihan cepat dan luka operasi sangat kecil.</p>
                    </div>
                </div>
            </div>
            <div class="section-body-related">
            </div>
        </div> -->
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from "vue";
    import { useVideoStore } from "@/stores/video";
    import { onMounted } from 'vue';
    const video = computed(() => useVideoStore().videoDetail);
    const videoWidth = computed(() => useVideoStore().videoWidth);
    useVideoStore().setVideoDetail(useRoute().params.slug);
    onMounted(() => {
        useVideoStore().setVideoWidth(document.getElementById('section-body-video').offsetWidth);
        const vw = () => {
            if(document.getElementById('section-body-video')){
                useVideoStore().setVideoWidth(document.getElementById('section-body-video').offsetWidth);
            }
        }
        (() => {
            window.addEventListener('resize', vw);
        })();
    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

<style scoped>
    section{
        padding: 2rem;
        padding-left: 5%;
        padding-right: 5%;
    }
    .section-body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .section-body-video{
        width: 75%;
        min-height: 450px;
        /* border: 1px solid green; */
        padding-right: 1rem;
        transition: 0.2s;
    }
    .sbv-video{
        width: 100%;
        height: 500px;
        /* border: 1px solid green; */
    }
    .sbv-video iframe{
        height: 100%;
    }
    .sbv-detail{
        padding-top: 2rem;
    }
    .sbv-description{
        text-align: justify;
    }
    .section-body-related{
        width: 25%;
        /* border: 1px solid green; */
        padding-left: 1rem;
        transition: 0.2s;
    }
    .sbr-part{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
    }
    .sbr-part-img{
        width: 33%;
    }
    .sbr-part-img img{
        width: 100%;
    }
    .sbr-part-detail{
        width: 67%;
        padding-left: 0.5rem;
    }
    @media only screen and (max-width: 750px){
        .section-body-video{
            width: 100%;
            padding-right: 0;
        }
        /* .sbv-video{
            height: 350px;
        } */
        .section-body-related{
            width: 100%;
            padding-left: 0;
        }
    }
    /*  */
</style>