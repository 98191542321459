<template>
    <section>
        <div class="section-head">
            <h2>Other Room</h2>
        </div>
        <div class="section-body" v-if="roomsShow">
            <div class="sb-inner">
                <div class="sb-part" v-for="room in arrayShuffle(roomsShow).slice(0, 3)" :key="room">
                    <router-link :to="'/rooms/detail/' + room.slug">
                        <div class="sb-part-inner">
                            <div class="spi-image">
                                <img :src="room.imgUrl" alt="rsia melinda"/>
                            </div>
                            <div class="spi-text">
                                <div class="spi-title">
                                    <h2>{{ room.title }}</h2>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="section-more" v-if="roomsShow.length < rooms.length">
                <button @click="useRoomStore().moreRooms();">Load More</button>
            </div>
        </div>
        <RoomOtherSkeleton v-else/>
    </section>
</template>

<script setup>
    import RoomOtherSkeleton from '@/components/skeletons/sections/RoomOtherSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useRoomStore } from '@/stores/room';
    import { arrayShuffle } from '@/functions/general';
    const rooms = computed(() => useRoomStore().rooms);
    const roomsShow = computed(() => useRoomStore().roomsShow);
    useRoomStore().setRooms();
</script>

<style scoped>
    section{
        padding: 2.5rem 0 2.5rem 0;
    }
    .section-head{
        padding: 0 10% 0 10%;
    }
    .section-head h2{
        font-size: 1.5rem;
        margin: 0 0 1rem 0;
        color: var(--navy);
    }
    .section-body{
        padding: 0 10% 0 10%;
        transition: 0.2s;
    }
    .sb-inner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .sb-part{
        width: 33.33%;
        height: 270px;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sb-part-inner{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
    }
    .spi-image{
        width: 100%;
        height: 100%;
        /* background: var(--grey); */
        animation: pulse-bg 1s infinite;
    }
    .spi-image img{
        width: 100%;
    }
    .spi-text{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(#0000009c, #000000);
        color: var(--white);
        padding: 0.5rem;
    }
    .spi-category{
        padding: 0.5rem 0 0.5rem 0;
        font-size: 0.75rem;
    }
    .spi-category span{
        padding: 0.5rem 1rem 0.5rem 1rem;
        background: var(--primary);
        color: var(--white);
        border-radius: 5px;
    }
    .spi-title{
        padding: 0.5rem 0 0 0;
    }
    .spi-title h2{
        font-size: 1.1rem;
        margin: 0;
        transition: 0.2s;
    }
    .section-more{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem 0 1rem 0;
    }
    .section-more button{
        font-family: poppins;
        font-weight: 500;
        font-size: 1rem;
        border: none;
        background: none;
        color: var(--grey);
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 0 2.5rem 0;
        }
        .section-head{
            padding: 0 4.5% 0 4.5%;
        }
        .section-body{
            padding: 0 4% 0 4%;
        }
    }
    @media only screen and (max-width: 1000px){
        .sb-part{
            width: 50%;
        }
        .spi-title h2{
            font-size: 1rem;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 0 2.5rem 0;
        }
        .section-head{
            padding: 0 2.5% 0 2.5%;
        }
        .section-body{
            padding: 0 1% 0 1%;
        }
        
        .spi-title h2{
            font-size: 0.9rem;
        }
    }
    @media only screen and (max-width: 600px){
        .sb-part{
            width: 100%;
        }
    }
</style>