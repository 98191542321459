<template>
    <div class="home-header" v-if="banner">
        <div class="hc-control" v-if="banner.length > 1">
            <div class="hc-button hcb-left">{{ '<' }}</div>
            <div class="hc-button hcb-right">{{ '>' }}</div>
        </div>
        <div class="hh-carousel carousel hhc-smooth">
            <div class="hc-part" v-for="slide in banner" :key="slide">
                <div class="hcp-inner">
                    <div class="hh-part hh-left">
                        <div class="hh-left-inner">
                            <h2 class="small-title">{{ slide.subtitle }}</h2>
                            <h1>{{ slide.title }}</h1>
                            <p class="p-2">{{ slide.description }}</p>
                            <!-- <div class="more button-main">
                                <router-link to="/services">Lihat Semua Services</router-link>
                            </div> -->
                        </div>
                    </div>
                    <div class="hh-part hh-right">
                        <div class="img-cover"></div>
                        <img fetchpriority="high" :src="slide.img_url" alt="RSIA Melinda" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useHomeStore } from '@/stores/home';
    import { onMounted } from 'vue';
    const banner = computed(() => useHomeStore().topBanner);
    // useHomeStore().setTopBanner();
    onMounted(() => {
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.querySelector('.carousel');
        const btleft = document.querySelector('.hcb-left');
        const btright = document.querySelector('.hcb-right');
        // const schildren = slider.children;
        const sparent = slider.parentElement;
        const end = () => {
            isDown = false;
            slider.classList.add('hhc-smooth');
            slider.scrollLeft = Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth;
        }
        const start = (e) => {
            isDown = true;
            slider.classList.remove('hhc-smooth');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }
        const move = (e) => {
            if(!isDown) return;
            // e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX) * 1.9;
            slider.scrollLeft = scrollLeft - dist;
        }
        const goLeft = (e) => {
            e.preventDefault();
            goTop();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) - sparent.scrollWidth;
        }
        const goRight = (e) => {
            e.preventDefault();
            goTop();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) + sparent.scrollWidth;
        }
        const sresize = () => {
            slider.scrollLeft = 0;
        }
        const goTop = () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
        (() => {
            slider.addEventListener('mousedown', start);
            slider.addEventListener('touchstart', start);
            slider.addEventListener('mousemove', move);
            slider.addEventListener('touchmove', move);
            slider.addEventListener('mouseleave', end);
            slider.addEventListener('mouseup', end);
            slider.addEventListener('touchend', end);
            slider.addEventListener('resize', sresize);
            if(banner.length > 1){
                btleft.addEventListener('click', goLeft);
                btright.addEventListener('click', goRight);
            }
        })();
    });
</script>

<style scoped>
    .home-header{
        position: relative;
        width: 100%;
        display: flex;
        height: 39rem;
        overflow: hidden;
        transition: 0.2s;
        user-select: none;
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.2s;
    }
    .hhc-smooth{
        scroll-behavior: smooth;
    }
    .hc-control{
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 5;
        display: flex;
        flex-wrap: nowrap;
        min-width: 100px;
        min-height: 50px;
        background: #1b467457;
        transition: 0.2s;
        user-select: none;
    }
    .hc-button{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 2rem;
        cursor: pointer;
    }
    .hc-button:hover{
        color: #eeeeee;
    }
    .hcb-right{
        background: #75C6EF;
    }
    .hc-part{
        display: inline-block;
        min-width: 100%;
        width: 100%;
        height: 100%;
    }
    .hcp-inner{
        position: relative;
        display: flex;
        height: 100%;
        overflow: hidden;
    }
    .hh-part{
        width: 50%;
        height: 100%;
    }
    .hh-left{
        position: relative;
        background: #1B4674;
        color: #ffffff;
        padding: 8.44rem;
        padding-left: 12.25rem;
        padding-right: 6.69rem;
        padding-bottom: 0;
        transition: 0.2s;
        height: 100%;
        z-index: 1;
    }
    .small-title{
        font-weight: 600;
        font-size: 1.1rem;
        color: #75C6EF;
    }
    .hh-left h1{
        font-family: poppins;
        font-size: 2rem;
        margin-top: 1.37rem;
        transition: 0.2s;
    }
    .p-2{
        margin-top: 1.19rem;
    }
    .more{
        /* margin-top: 2rem; */
        padding: 1rem 0 1rem 0;
    }
    .hh-right{
        position: relative;
        background: #1B4674;
        transition: 0.2s;
        display: flex;
        justify-content: center;
        align-items: start;
    }
    .img-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .hh-right img{
        /* height: 100%; */
        width: 100%;
        transition: 0.2s;
        z-index: 0;
    }
    @media only screen and (max-width: 1500px){
        .home-header{
            height: 32rem;
        }
        .hh-left{
            padding-top: 2.6rem;
            padding-right: 2.6rem;
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .home-header{
            height: 26rem;
        }
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 750px){
        .hh-left{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #1b467454;
            padding-top: 3.6rem;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .hh-left-inner{
            position: absolute;
            left: 0;
            bottom: 50px;
            width: 100%;
            padding: 0 2.5% 0 2.5%;
        }
        .hh-left h1{
            font-size: 2rem;
            width: auto;
        }
        .hc-control{
            left: 0;
        }
        .hh-right{
            width: 100%;
        }
    }
    @media only screen and (max-width: 500px){
        .hh-left h1{
            font-size: 1.5rem;
            width: auto;
        }
        .hh-right img{
            /* width: unset; */
        }
    }
    @media only screen and (max-width: 350px){
        .hh-left{
            padding-top: 1rem;
        }
    }
</style>