<template>
    <RoomDetailComponent/>
    <RoomOtherComponent/>
</template>

<script setup>
    import RoomDetailComponent from '@/components/rooms/RoomDetailComponent.vue';
    import RoomOtherComponent from '@/components/rooms/RoomOtherComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>


