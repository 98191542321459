<template>
    <section>
        <div class="section-inner">
            <div class="si-top" v-if="header">
                <div class="si-text">
                    <h2>{{ header.sub_title }}</h2>
                    <h1>{{ header.title }}</h1>
                    <div class="si-text-description">
                        <p>{{ wordsLimit(removeHTML(header.description), 750) }}</p>
                    </div>
                    <!-- <div class="si-text-description" v-html="header.description"></div> -->
                    <div class="si-box">
                        <div class="si-search" v-if="categories">
                            <div class="si-search-select">
                                <div class="sss-button" id="sss-button-sss" @click="topSearchSelect('sss-button-sss');">
                                    <div class="button-cover float-button"></div>
                                    <div class="sss-text">
                                        <div class="sss-text-title">Layanan</div>
                                        <div class="sss-text-button">{{ searchBar.select.title }}</div>
                                    </div>
                                    <div class="sss-icon">
                                        <div class="sss-icon-inner float-arrow">
                                            <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="sss-option float">
                                    <div class="sss-option-button" @click="useServiceStore().searchbarSelect('All Services', 'all');">
                                        <span>Semua</span>
                                    </div>
                                    <div class="sss-option-button" @click="useServiceStore().searchbarSelect(category.name, category.slug);" v-for="category in categories" :key="category">
                                        <span>{{ category.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="si-search-input">
                                <input type="text" id="sss-input-sss" :value="searchBar.input.value" placeholder="masukkan nama layanan" autocomplete="off" @input="searchbarInput('sss-input-sss');" />
                            </div>
                            <div class="si-search-button">
                                <span>Search Services</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="si-image">
                    <img :src="header.img_url" alt="rsia melinda"/>
                </div>
            </div>
            <RoomTopSkeleton v-else/>
        </div>
    </section>
</template>

<script setup>
    import RoomTopSkeleton from '@/components/skeletons/sections/RoomTopSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useRoomStore } from '@/stores/room';
    import { topSearchSelect, removeHTML, wordsLimit } from '@/functions/general';
    import { searchbarInput } from '@/functions/service';
    const searchBar = computed(() => useRoomStore().searchBar);
    const categories = computed(() => useRoomStore().categories);
    const header = computed(() => useRoomStore().roomHeader);
    useRoomStore().setRooms();
</script>

<style scoped>
    section{
        transition: 0.2s;
        padding: 0 0 2.5rem 10%;
        background: var(--navy);
    }
    .section-inner{
        position: relative;
        width: 100%;
        height: 500px;
        display: flex;
        flex-wrap: wrap;
        transition: 0.2s;
    }
    .si-top{
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .si-text{
        width: 50%;
        padding: 2.5rem 2rem 0 0;
        transition: 0.2s;
    }
    .si-text h2{
        font-size: 1.1rem;
        color: var(--primary);
        margin: 0;
    }
    .si-text h1{
        font-size: 2rem;
        color: var(--white);
        margin: 1rem 0 1rem 0;
        transition: 0.2s;
    }
    .si-text-description{
        padding: 0 0 5rem 0;
        color: var(--white);
    }
    .si-image{
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: 0.2s;
    }
    .si-image img{
        height: 100%;
    }
    .si-box{
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 75%;
        transition: 0.2s;
    }
    .si-search{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 75px;
        transition: 0.2s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
    }
    .si-search-select{
        position: relative;
        width: 30%;
        height: 100%;
        user-select: none;
        background: var(--white);
        transition: 0.2s;
    }
    .sss-button{
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
    }
    .button-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .sss-text{
        width: 90%;
    }
    .sss-text-title{
        font-weight: 700;
        padding: 0 0.5rem 0 0.5rem;
    }
    .sss-text-button{
        padding: 0.5rem;
        font-size: 0.9rem;
    }
    .sss-icon{
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sss-icon-inner{
        text-align: center;
        width: 20px;
        height: 20px;
    }
    .sss-icon img{
        max-width: 100%;
        transition: 0.2s;
    }
    .sss-option{
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        background: var(--white);
        display: none;
        -webkit-animation-name: animatesssoption;
        -webkit-animation-duration: 0.4s;
        animation-name: animatesssoption;
        animation-duration: 0.4s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
        z-index: 5;
    }
    @-webkit-keyframes animatesssoption {
        from {top:150%; opacity:0}
        to {top:110%; opacity:1}
    }
    @keyframes animatesssoption {
        from {top:150%; opacity:0}
        to {top:110%; opacity:1}
    }
    .float-active{
        display: block;
    }
    .sss-option-button{
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .sss-option-button:hover{
        background: var(--smoke);
    }
    .si-search-input{
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid var(--softgrey);
        padding: 1rem;
        background: var(--white);
        transition: 0.2s;
    }
    .si-search-input input{
        border: none;
        outline: none;
        width: 100%;
        height: 50px;
        color: var(--grey);
        font-size: 1.2rem;
    }
    .si-search-button{
        width: 30%;
        height: 100%;
        background: var(--primary);
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 0 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        section{
            padding: 0 5% 0 5%;
        }
        .section-inner{
            height: auto;
        }
        .si-text{
            width: 100%;
            padding: 2.5rem 0 0 0;
        }
        .si-image{
            width: 0;
            height: 0;
        }
        .si-box{
            position: relative;
            width: 100%;
        }
        .si-search{
            height: auto;
            box-shadow: unset;
        }
        .si-search-select{
            width: 50%;
            height: 75px;
            border-left: 1px solid var(--softgrey);
            border-bottom: 1px solid var(--softgrey);
        }
        .si-search-input{
            width: 50%;
            height: 75px;
            border-right: 1px solid var(--softgrey);
            border-bottom: 1px solid var(--softgrey);
        }
        .si-search-button{
            width: 100%;
            height: 75px;
            margin-top: 1rem;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 0 2.5% 0 2.5%;
        }
        .si-text h1{
            font-size: 1.5rem;
        }
        .si-search-select{
            width: 100%;
            border-right: 1px solid var(--softgrey);
        }
        .si-search-input{
            width: 100%;
            margin-top: 1rem;
            border: 1px solid var(--softgrey);
        }
        .si-search-button{
            width: 100%;
        }
    }
</style>