<template>
    <section>
        <div class="section-inner" v-if="body">
            <div class="si-part" v-for="link in body.list" :key="link">
                <div class="si-part-inner">
                    <div class="spi-icon">
                        <img :src="link.img_url" alt="melinda hospital"/>
                    </div>
                    <div class="spi-detail">
                        <h2>{{ link.name }}</h2>
                        <p>{{ link.description }}</p>
                        <div class="spi-link">
                            <a :href="link.link" target="_blank">
                                <div class="link-button">
                                    <div class="lb-text">
                                        <span>Link to Website</span>
                                    </div>
                                    <div class="lb-icon">
                                        <img src="../../assets/images/icons/arrow-2.png" alt="arrow"/>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LinkBottomSkeleton v-else/>
    </section>
</template>

<script setup>
    import LinkBottomSkeleton from '@/components/skeletons/parts/LinkBottomSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useLinkStore } from '@/stores/link';
    const body = computed(() => useLinkStore().body);
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        background: var(--cream-bg);
        transition: 0.2s;
    }
    .section-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .si-part{
        width: 33.33%;
        transition: 0.2s;
    }
    .si-part-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .spi-icon{
        width: 25%;
        transition: 0.2s;
    }
    .spi-icon img{
        max-width: 90%;
    }
    .spi-detail{
        width: 75%;
        transition: 0.2s;
        padding: 0 0.5rem 0 0.5rem;
    }
    .spi-detail h2{
        font-family: poppins;
        font-size: 1.75rem;
        font-weight: 700;
        color: var(--navy);
        margin: 0;
    }
    .spi-detail p{
        font-size: 1rem;
        color: var(--navy);
        margin: 0;
        text-align: justify;
    }
    .spi-link{
        padding: 2rem 0 1rem 0;
    }
    .link-button{
        display: flex;
        width: 100%;
        max-width: 200px;
        max-height: 50px;
        color: var(--white);
        background: var(--primary);
        padding: 0.5rem 2rem 0.5rem 2rem;
        font-size: 1rem;
        border-radius: 5rem;
    }
    .lb-text{
        width: 90%;
    }
    .lb-icon{
        width: 10%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .si-part{
            width: 100%;
        }
        .spi-icon{
            width: 15%;
        }
        .spi-detail{
            width: 85%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
    }
</style>