import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ServicesView from '../views/ServicesView.vue';
import ServiceDetailView from '../views/ServiceDetailView.vue';
// import ArticlesView from '../views/ArticlesView.vue';
// import ArticleCategoryView from '../views/ArticleCategoryView.vue';
import ArticleDetailView from '../views/ArticleDetailView.vue';
import RoomView from '../views/RoomView.vue';
import RoomDetailView from '../views/RoomDetailView.vue';
import RoomAvailabilityView from '../views/RoomAvailabilityView.vue';
import DoctorsView from '../views/DoctorsView.vue';
import DoctorListView from '../views/DoctorListView.vue';
// import EventView from '../views/EventView.vue';
// import EventDetailView from '../views/EventDetailView.vue';
// import EventFormView from '../views/EventFormView.vue';
// import PromoView from '../views/PromoView.vue';
// import PromoDetailView from '../views/PromoDetailView.vue';
import LinkView from '../views/LinkView.vue';
import VideoView from '../views/VideoView.vue';
import VideoDetailView from '../views/VideoDetailView.vue';
import GalleryView from '../views/GalleryView.vue';
import GalleryDetailView from '../views/GalleryDetailView.vue';
import ContactView from '../views/ContactView.vue';
// import SitemapView from '../views/SitemapView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/services/detail/:slug',
    name: 'serviceDetail',
    component: ServiceDetailView
  },
  // {
  //   path: '/articles',
  //   name: 'articles',
  //   component: ArticlesView
  // },
  // {
  //   path: '/articles/category/:slug',
  //   name: 'articleCategory',
  //   component: ArticleCategoryView
  // },
  {
    path: '/articles/detail/:slug',
    name: 'articleDetail',
    component: ArticleDetailView
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: RoomView
  },
  {
    path: '/rooms/detail/:slug',
    name: 'roomDetail',
    component: RoomDetailView
  },
  {
    path: '/rooms/availability',
    name: 'roomAvailability',
    component: RoomAvailabilityView
  },
  {
    path: '/our-room/availability',
    redirect: '/rooms/availability',
  },
  {
    path: '/doctors',
    name: 'doctors',
    component: DoctorsView
  },
  {
    path: '/doctor-list',
    name: 'doctorList',
    component: DoctorListView
  },
  // {
  //   path: '/articles',
  //   name: 'articles',
  //   component: ArticlesView
  // },
  // {
  //   path: '/articles/detail/:slug',
  //   name: 'articleDetail',
  //   component: ArticleDetailView
  // },
  // {
  //   path: '/event',
  //   name: 'event',
  //   component: EventView
  // },
  // {
  //   path: '/event/detail/:slug',
  //   name: 'eventDetail',
  //   component: EventDetailView
  // },
  // {
  //   path: '/event/form/:slug',
  //   name: 'eventForm',
  //   component: EventFormView
  // },
  // {
  //   path: '/promo',
  //   name: 'promo',
  //   component: PromoView
  // },
  // {
  //   path: '/promo/detail/:slug',
  //   name: 'promoDetail',
  //   component: PromoDetailView
  // },
  // {
  //   path: '/careers',
  //   name: 'careers',
  //   component: ArticlesView
  // },
  // {
  //   path: '/auction',
  //   name: 'auction',
  //   component: ArticlesView
  // },
  {
    path: '/links',
    name: 'links',
    component: LinkView
  },
  {
    path: '/video',
    name: 'video',
    component: VideoView
  },
  {
    path: '/video/detail/:slug',
    name: 'videoDetail',
    component: VideoDetailView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/gallery/detail/:slug',
    name: 'galleryDetail',
    component: GalleryDetailView
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: ContactView
  },
  // {
  //   path: '/sitemap.xml',
  //   name: 'siteMap',
  //   component: SitemapView
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


