export const servicesLayout = [
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
];
export const galleryLayout = [
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
];