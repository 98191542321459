<template>
    <HomeTopComponent/>
    <HomeInfoComponent/>
    <HomeServiceComponent/>
    <HomePointComponent/>
    <HomeVideoComponent/>
    <HomeArticlesComponent/>
</template>

<script setup>
    import HomeTopComponent from '@/components/home/HomeTopComponent.vue';
    import HomeInfoComponent from '@/components/home/HomeInfoComponent.vue';
    import HomeServiceComponent from '@/components/home/HomeServiceComponent.vue';
    import HomePointComponent from '@/components/home/HomePointComponent.vue';
    import HomeVideoComponent from '@/components/home/HomeVideoComponent.vue';
    import HomeArticlesComponent from '@/components/home/HomeArticlesComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'RS Melinda 2 Bandung',
            description: 'Memiliki motto Melayani Sepenuh Hati bagi keluarga besar Melinda Hospital berarti melayani harus dengan sepenuh hati dan disertai dengan pikiran, perasaan dan kehendak yang tulus.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>