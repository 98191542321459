<template>
    <HomeTopCarousel v-if="banner"/>
    <HomeTopCarouselSkeleton v-else/>
</template>

<script setup>
    import HomeTopCarousel from '@/components/home/HomeTopCarouselComponent.vue';
    import HomeTopCarouselSkeleton from '@/components/skeletons/sections/HomeTopCarouselSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useHomeStore } from '@/stores/home';
    const banner = computed(() => useHomeStore().topBanner);
    useHomeStore().setTopBanner();
</script>

<style scoped>
    /* .home-header{
        position: relative;
        width: 100%;
        display: flex;
        height: 39rem;
        overflow: hidden;
        transition: 0.2s;
        user-select: none;
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.2s;
    }
    .hhc-smooth{
        scroll-behavior: smooth;
    }
    .hc-control{
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 5;
        display: flex;
        flex-wrap: nowrap;
        min-width: 100px;
        min-height: 50px;
        background: #1b467457;
        transition: 0.2s;
        user-select: none;
    }
    .hc-button{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 2rem;
        cursor: pointer;
    }
    .hc-button:hover{
        color: #eeeeee;
    }
    .hcb-right{
        background: #75C6EF;
    }
    .hc-part{
        display: inline-block;
        min-width: 100%;
        width: 100%;
        height: 100%;
    }
    .hcp-inner{
        position: relative;
        display: flex;
        height: 100%;
        overflow: hidden;
    }
    .hh-part{
        width: 50%;
        height: 100%;
    }
    .hh-left{
        background: #1B4674;
        color: #ffffff;
        padding: 8.44rem;
        padding-left: 12.25rem;
        padding-right: 6.69rem;
        padding-bottom: 0;
        transition: 0.2s;
        height: 100%;
        z-index: 1;
    }
    .p-1{
        font-weight: 600;
        color: #75C6EF;
    }
    .hh-left h1{
        font-family: poppins;
        font-size: 2rem;
        margin-top: 1.37rem;
        transition: 0.2s;
    }
    .p-2{
        margin-top: 1.19rem;
    }
    .more{
        margin-top: 2rem;
    }
    .hh-right{
        position: relative;
        background: #1B4674;
        transition: 0.2s;
    }
    .img-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .hh-right img{
        height: 100%;
        width: 100%;
        transition: 0.2s;
        z-index: 0;
    }
    @media only screen and (max-width: 1500px){
        .home-header{
            height: 32rem;
        }
        .hh-left{
            padding-top: 2.6rem;
            padding-right: 2.6rem;
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .home-header{
            height: 26rem;
        }
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 750px){
        .hh-left{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #1b467454;
            padding-top: 3.6rem;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .hh-left h1{
            font-size: 2rem;
            width: auto;
        }
        .hc-control{
            left: 0;
        }
        .hh-right{
            width: 100%;
        }
    }
    @media only screen and (max-width: 500px){
        .hh-left h1{
            font-size: 1.5rem;
            width: auto;
        }
        .hh-right img{
            width: unset;
        }
    }
    @media only screen and (max-width: 350px){
        .hh-left{
            padding-top: 1rem;
        }
    } */
</style>