import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import backend1Axios from "../backend1.js";
import axiosMain from "../axiosMain.js";
import { createSlug } from "@/functions/general.js";

export const useHomeStore = defineStore("home", {
    state: () => ({
        // ==================== states used on home page and its relation
        topBanner: null,
        services: null,
        recommendation: null,
        articles: null,
        point: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on home page and its relation
        async setTopBanner(){
            try{
                const response = await axiosClient.get(`home/banner`);
                this.topBanner = response.data.carousel1;
                this.recommendation = {
                    head: {
                        title: response.data.title
                    }, 
                    body: {
                        kid: {
                            title: response.data.title_vertical, 
                            image: response.data.images_vertical,
                            link: response.data.link_vertical
                        }, 
                        family: {
                            title: response.data.title_horizontal, 
                            image: response.data.images_horizontal,
                            link: response.data.link_horizontal
                        }
                    }
                };
                this.setHomeData();
                // console.log(this.recommendation);
            }catch(error){
                console.log(error);
            }
        },
        async setHomeData(){
            try{
                const response = await axiosClient.get(`home`);
                const article = await backend1Axios.get(`home`);
                const rsarticle = await axiosMain.get(`article/content`);
                this.services = article.data.services;
                for(var i = 0; i < this.services.length; i++){
                    this.services[i].slug = createSlug(this.services[i].name);
                }
                // this.rooms = article.data.rooms;
                // this.articles = article.data.news;
                this.articles = rsarticle.data.data.slice(0, 3);
                this.point = response.data.point;
                console.log(this.articles);
            }catch(error){
                console.log(error);
            }
        }
    }
});