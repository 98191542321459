<template>
    <RoomAvailabilityHeaderComponent/>
    <RoomAvailabilityComponent/>
    <!-- <FooterContent/> -->
</template>
<script setup>
    import RoomAvailabilityHeaderComponent from '@/components/rooms/RoomAvailabilityHeaderComponent.vue';
    import RoomAvailabilityComponent from '@/components/rooms/RoomAvailabilityComponent.vue';
    // import FooterContent from '@/components/utils/FooterContent.vue';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>
<style>
    /*  */
</style>