<template>
    <section>
        <div class="section-body" v-if="galleryDetail">
            <div class="sb-inner">
                <div class="sb-part" v-for="gallery in galleryDetail.galeries" :key="gallery">
                    <div class="sb-part-inner">
                        <div class="spi-image">
                            <img :src="gallery.img_url" alt="rsia melinda"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <GalleryDetailSkeleton v-else/>
    </section>
</template>

<script setup>
    import GalleryDetailSkeleton from '@/components/skeletons/sections/GalleryDetailSkeleton.vue';
    import { useRoute } from 'vue-router';
    import { computed } from '@vue/reactivity';
    import { useGalleryStore } from '@/stores/gallery';
    const galleryDetail = computed(() => useGalleryStore().galleryDetail);
    useGalleryStore().setGalleryDetail(useRoute().params.slug);
</script>

<style scoped>
    section{
        padding: 0 0 2.5rem 0;
    }
    .section-body{
        padding: 0 10% 0 10%;
        transition: 0.2s;
    }
    .sb-inner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .sb-part{
        width: 33.33%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sb-part-inner{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
    }
    .spi-image{
        width: 100%;
        height: 100%;
    }
    .spi-image img{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 0 0 2.5rem 0;
        }
        .section-body{
            padding: 0 4.5% 0 4.5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .section-head{
            padding: 0 5% 225px 5%;
        }
        .sb-part{
            width: 50%;
        }
        .spi-title h2{
            font-size: 1rem;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 0 0 2.5rem 0;
        }
        .section-body{
            padding: 0 1% 0 1%;
        }
        
        .spi-title h2{
            font-size: 0.9rem;
        }
    }
    @media only screen and (max-width: 600px){
        .sb-part{
            width: 100%;
        }
    }
</style>