<template>
    <DoctorTopComponent/>
</template>

<script setup>
    import DoctorTopComponent from '@/components/doctors/DoctorTopComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Dokter RS Melinda 2 Bandung',
            description: 'Rumah Sakit Melinda 2 ini menghadirkan Dokter-Dokter Spesialis yang Profesional serta berpengalaman dibidangnya.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>