<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <h2>Related Services</h2>
            </div>
            <div class="si-body" v-if="services">
                <div class="sb-inner">
                    <div class="sb-inner-part" v-for="service in arrayShuffle(services).slice(0, 2)" :key="service">
                        <div class="sip-inner">
                            <h4>{{ service.category.name }}</h4>
                            <h3>{{ service.name }}</h3>
                            <p>{{ wordsLimit(removeHTML(service.description), 200) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useServiceStore } from '@/stores/service';
    import { removeHTML, wordsLimit, arrayShuffle } from '@/functions/general';
    const services = computed(() => useServiceStore().services);
    useServiceStore().setServices();
</script>

<style scoped>
    section{
        position: relative;
        padding: 2.5rem 10% 2.5rem 10%;
        overflow: hidden;
        background: var(--cream-bg);
    }
    .si-head h2{
        font-size: 1.5rem;
        color: var(--navy);
        margin: 0 0 1rem 0;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-inner-part{
        width: 50%;
        color: var(--grey);
    }
    .sip-inner{
        padding: 0 1rem 0 0;
    }
    .sip-inner h4{
        font-size: 1rem;
        color: var(--primary);
    }
    .sip-inner h3{
        font-size: 1.2rem;
        color: var(--navy);
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .sb-inner-part{
            width: 100%;
        }
    }
</style>