<template>
    <section>
        <div class="section-content" v-if="contactCard && contactData">
            <div class="sc-inner">
                <div class="sci-left">
                    <p class="sci-small-title">{{ contactCard.sub_title }}</p>
                    <h2 class="sci-title">{{ contactCard.title }}</h2>
                    <div class="sci-detail">
                        <div class="sci-detail-part">
                            <h3>Address</h3>
                            <p>{{ contactData.contact_us.address }}</p>
                        </div>
                        <div class="sci-detail-part">
                            <h3>Contact</h3>
                            <div v-html="contactData.contact_us.fax"></div>
                        </div>
                        <div class="sci-detail-part">
                            <h3>Email</h3>
                            <p>{{ contactData.contact_us.email }}</p>
                        </div>
                    </div>
                </div>
                <div class="sci-right">
                    <div class="sci-form" id="sci-form-contact">
                        <div class="sci-input select" v-if="specialists">
                            <input type="hidden" name="doctor_speciality_id" label="Spesialis" id="cform-input-doctor_speciality_id" required />
                            <div class="sci-label">Spesialis</div>
                            <div class="sbi-select">
                                <div class="sbi-select-button" id="ssb-select-specialist" @click="optionToggle('ssb-select-specialist');">
                                    <div class="btn-cover"></div>
                                    <div class="ssb-text" id="ssb-text-specialist">
                                        <span>Pilih Spesialis</span>
                                    </div>
                                    <div class="ssb-icon float-icon">
                                        <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                    </div>
                                </div>
                                <div class="sbi-select-option float">
                                    <div class="sso-button" v-for="sp in specialists" :key="sp" @click="selectSpecialist(sp);">
                                        <span>{{ sp.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="cform-alert" id="cform-alert-doctor_speciality_id"></div>
                        </div>
                        <div class="sci-input input">
                            <div class="sci-label">Full Name</div>
                            <input type="text" name="full_name" label="Full Name" id="cform-input-full_name" placeholder="Full Name" autocomplete="off" required />
                            <div class="cform-alert" id="cform-alert-full_name"></div>
                        </div>
                        <div class="sci-input input">
                            <div class="sci-label">Email Address</div>
                            <input type="text" name="email" label="Email Address" id="cform-input-email" placeholder="Email Address" autocomplete="off" email required />
                            <div class="cform-alert" id="cform-alert-email"></div>
                        </div>
                        <div class="sci-input input">
                            <div class="sci-label">Phone Number</div>
                            <input type="number" name="phone" label="Phone Number" id="cform-input-phone" placeholder="Phone Number" autocomplete="off" phone required />
                            <div class="cform-alert" id="cform-alert-phone"></div>
                        </div>
                        <div class="sci-input input">
                            <div class="sci-label">Messages</div>
                            <textarea name="messages" label="Messages" id="cform-input-messages" cols="30" rows="10" required ></textarea>
                            <div class="cform-alert" id="cform-alert-messages"></div>
                        </div>
                        <div class="sci-input">
                            <button @click="submitForm">
                                Send Us
                                <img src="../../assets/images/icons/wacright.png" alt="rsia melinda"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useContactStore } from '@/stores/contact';
    import { useDoctorStore } from '@/stores/doctor';
    import { optionToggle, selectSpecialist, submitForm } from '@/functions/contact';
    const contactCard = computed(() => useContactStore().contactCard);
    const contactData = computed(() => useContactStore().contactData);
    const specialists = computed(() => useDoctorStore().specialistList);
    useDoctorStore().setSpecialists();
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        color: #1B4674;
        background: #ffffff;
        transition: 0.2s;
    }
    .section-header{
        text-align: left;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        margin-top: 2rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        width: 100%;
        padding-top: 2rem;
        transition: 0.2s;
    }
    .sci-left{
        width: 50%;
        transition: 0.2s;
    }
    .sci-right{
        width: 50%;
        color: #575757;
        transition: 0.2s;
    }
    .sci-small-title{
        color: #75C6EF;
        font-size: 1.1rem;
        font-weight: 600;
        margin: 0 0 1rem 0;
    }
    .sci-title{
        font-family: poppins;
        font-size: 2.5rem;
        font-weight: 700;
        width: 75%;
        line-height: 1;
        margin-top: 1rem;
        transition: 0.2s;
    }
    .sci-detail{
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;
        color: #575757;
        transition: 0.2s;
    }
    .sci-detail-part{
        width: 50%;
        padding: 1rem;
        padding-left: 0;
        transition: 0.2s;
    }
    .sci-detail-part h3{
        margin-bottom: 0.5rem;
    }
    .sci-detail-part p{
        margin: 0;
        margin-bottom: 0.5rem;
        line-height: 1;
    }
    .sci-input{
        margin-bottom: 1rem;
        width: 100%;
    }
    .sbi-select{
        position: relative;
        color: var(--grey);
        font-size: 0.9rem;
        user-select: none;
    }
    .sbi-select-button{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        background: var(--smoke);
        padding: 1rem;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
    }
    .btn-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .ssb-text{
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .ssb-icon{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .ssb-icon img{
        transition: 0.2s;
    }
    .ssb-icon-active img{
        transform: rotate(180deg);
    }
    .sbi-select-option{
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        min-height: 50px;
        display: none;
        background: var(--white);
        border: 2px solid var(--smoke);
        overflow: hidden;
        z-index: 1;
    }
    .sso-active{
        display: block;
    }
    .sso-button{
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
        cursor: pointer;
        user-select: none;
    }
    .sso-button:hover{
        background: var(--smoke);
    }
    .sso-note{
        margin: 1rem 0 1rem 0;
        text-align: center;
        font-size: 1rem;
        font-family: roboto;
        font-weight: 300;
        font-style: italic;
        color: var(--softgrey);
    }
    .sci-label{
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .sci-input select, input, textarea{
        width: 100%;
        border: none;
        border-bottom: 1px solid #D3D3D3;
        outline: none;
        padding-left: none;
        color: #575757;
    }
    .sci-input select{
        height: 2rem;
    }
    .sci-input input{
        height: 2rem;
    }
    .sci-input textarea{
        height: 5rem;
        font-family: roboto;
    }
    .cform-alert{
        color: var(--red);
        font-style: italic;
        font-size: 0.75rem;
    }
    .sci-input button{
        font-size: 1.1875;
        min-width: 150px;
        width: 25%;
        height: 2rem;
        padding: 0.5rem;
        background: #1B4674;
        color: #ffffff;
        border: none;
        border-radius: 50px;
    }
    .sci-input button:hover{
        background: #2c5b8d;
    }
    .sci-input button img{
        float: right;
        padding-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 0;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sci-title{
            font-size: 2rem;
            width: 90%;
            margin-bottom: 1rem;
        }
        .sci-detail{
            margin-top: 0;
        }
        .sci-detail-part{
            width: 33.33%;
            padding-top: 0;
            padding-bottom: 0;
        }
        .sci-right{
            width: 100%;
            margin-top: 2rem;
        }
    }
    @media only screen and (max-width: 710px){
        section{
            padding-top: 1rem;
        }
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-detail-part{
            width: 100%;
        }
    }
</style>