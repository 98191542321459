<template>
    <DoctorListComponent/>
</template>

<script setup>
    import DoctorListComponent from '@/components/doctors/DoctorListComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Temui Ahli dan Dokter Kami',
            description: 'RS Melinda 2 Bandung menghadirkan dokter-dokter spesialis yang sangat berpengalaman, yang didukung oleh tenaga ahli di bidangnya masing-masing sehingga dapat memberikan yang terbaik untuk para calon pasien.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>