import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useRoomAvailabilityStore = defineStore("roomAvailability", {
    state: () => ({
        // ==================== states used on room availability page and its relation
        roomAvailabilityData: null,
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on room availability page and its relation
        async setRoomAvailability(){
            const response = await axiosClient.get(`bed`);
            this.roomAvailabilityData = response.data;
        },
    }
});