<template>
    <GalleryDetailTopComponent/>
    <GalleryDetailComponent/>
</template>

<script setup>
    import GalleryDetailTopComponent from '@/components/gallery/GalleryDetailTopComponent.vue';
    import GalleryDetailComponent from '@/components/gallery/GalleryDetailComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>

