<template>
    <section>
        <div class="section-body">
            <div class="info-list">
                <div class="info-list-part">
                    <div class="table-card">
                        <div class="tc-head">
                            <!-- <div class="tc-head-title">
                                <span>Kamar yang Tersedia</span>
                            </div> -->
                            <div class="tc-head-row">
                                <div class="thr-part">
                                    <span>Kamar</span>
                                </div>
                                <div class="thr-part">
                                    <span>Jumlah Tempat Tidur</span>
                                </div>
                                <div class="thr-part">
                                    <span>Terisi</span>
                                </div>
                                <div class="thr-part">
                                    <span>Tersedia</span>
                                </div>
                            </div>
                        </div>
                        <div class="tc-body">
                            <div class="tc-body-row" v-for="bed in roomAvailabilityData" :key="bed" :class="{ 'tbr-empty': bed.remaining === 0 }">
                                <div class="tbr-part">
                                    <span>{{ bed.name }}</span>
                                </div>
                                <div class="tbr-part">
                                    <span>{{ bed.amount }}</span>
                                </div>
                                <div class="tbr-part">
                                    <span>{{ (bed.amount - bed.remaining) }}</span>
                                </div>
                                <div class="tbr-part">
                                    <span>{{ bed.remaining }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
    import { computed } from "vue";
    import { useRoomAvailabilityStore } from "@/stores/roomAvailability";
    // // import HeadingContent from "@/components/utils/HeadingContent.vue";
    // // const vtitle = 'Biportal Endoscopic Spine Surgery';
    // // const vdesc = 'Biportal Endoscopic Spine Surgery merupakan prosedur bedah minimal invasif / minim luka di tubuh untuk menangani tulang belakang yang mengakibatkan urat saraf kejepit';
    // function wordsLimit(words, max){
    //     if(words.length > max) words = words.substring(0, max) + '...';
    //     return words;
    // }
    // function removeHTML(html){
    //     // var html = "<p>Some HTML</p>";
    //     var div = document.createElement("div");
    //     div.innerHTML = html;
    //     var text = div.textContent || div.innerText || "";
    //     return text;
    // }
    const roomAvailabilityData = computed(() => useRoomAvailabilityStore().roomAvailabilityData);
    useRoomAvailabilityStore().setRoomAvailability();
    // const videoCategories = computed(() => useRoomAvailabilityStore().videoCategories);
    // useRoomAvailabilityStore().setVideoCategories();
</script>
<style scoped>
    section{
        padding: 1rem 5% 2rem 5%;
        transition: 0.2s;
    }
    .info-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .info-list-part{
        width: 100%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .table-card{
        position: relative;
        overflow: hidden;
        min-height: 200px;
        height: 100%;
        border: 1px solid var(--softgrey);
    }
    .tc-head-title{
        width: 100%;
        height: 50px;
        display: flex;
        background: var(--navy);
        color: var(--white);
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: large;
    }
    .tc-head-row{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        background: var(--softgrey);
        padding: 1rem;
    }
    .thr-part{
        width: 25%;
        text-align: center;
        font-weight: 500;
    }
    .tc-body-row{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        padding: 0.5rem;
    }
    .tbr-empty{
        background: var(--red);
        color: var(--white);
    }
    .tbr-part{
        width: 25%;
        text-align: center;
    }
    @media only screen and (max-width: 1500px){
        /*  */
    }
    /* @media only screen and (max-width: 1000px){
        section{
            padding-top: 5rem;
        }
    } */
    @media only screen and (max-width: 750px){
        section{
            padding: 1rem 2.5% 2rem 2.5%;
        }
        .info-list-part{
            width: 100%;
        }
    }
    @media only screen and (max-width: 500px){
        section{
            padding: 1rem 1% 2rem 1%;
        }
    }
</style>