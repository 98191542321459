import { defineStore } from "pinia";
import axiosClient from "../axios.js";
// import { createSlug } from "@/functions/axios.js";

export const usePictureStore = defineStore("picture", {
    state: () => ({
        // ==================== states used on video page and its relation
        allVideos: null,
        videos: null,
        searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        categories: null,
        videosShow: null,
        videoDetail: null,
        videosByCategory: null,
        abcShow: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on video page and its relation
        async setVideos(){
            if(!this.allVideos){
                try{
                    const response = await axiosClient.get(`video`);
                    this.allVideos = response.data.data;
                    this.videos = response.data.data;
                    this.categories = [{name: 'Semua', slug: 'all'}];
                    // for(var i = 0; i < this.categories.length; i++){
                    //     this.categories[i].slug = createSlug(this.categories[i].name);
                    // }
                    this.showVideos(6);
                    console.log(this.videos);
                }catch(error){
                    console.log(error);
                }
            }
        },
        async setVideoDetail(slug){
            if(!this.allVideos){
                await this.setVideos();
            }
            this.videoDetail = null;
            try{
                const response = await axiosClient.get(`article/` + slug);
                this.videoDetail = response.data.data;
                // console.log(this.videoDetail);
            }catch(error){
                console.log(error);
            }
        },
        async setVideosByCategory(slug){
            if(!this.allVideos){
                await this.setVideos();
            }
            var ct = null;
            var at = [];
            for(var i = 0; i < this.categories.length; i++){
                if(this.categories[i].slug == slug){
                    ct = this.categories[i].name;
                }
            }
            for(i = 0; i < this.allVideos.length; i++){
                if(this.allVideos[i].category){
                    if(this.allVideos[i].category.name == ct){
                        at.push(this.allVideos[i]);
                    }
                }
            }
            this.videosByCategory = at;
            this.showAbc(6);
        },
        showVideos(limit){
            this.videosShow = this.videos.slice(0, limit);
        },
        moreVideos(){
            const ln = this.videosShow.length;
            this.showVideos((ln + 6));
        },
        searchbarSelect(category, slug){
            this.searchBar.select.title = category;
            this.searchBar.select.value = slug;
            this.searchService();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchService();
        },
        searchService(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ // if selected specialist
                for(var i = 0; i < this.allVideos.length; i++){
                    if(this.allVideos[i].category){
                        if(this.allVideos[i].category.name == this.searchBar.select.title){
                            newsd.push(this.allVideos[i]);
                        }
                    }
                }
            }else{
                newsd = this.allVideos;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    if(newsd[i].name.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.videos = result;
            }else{
                this.videos = newsd;
            }
            this.showVideos(6);
        },
        showAbc(limit){
            this.abcShow = this.videosByCategory.slice(0, limit);
        },
        moreAbc(){
            const ln = this.abcShow.length;
            this.showAbc((ln + 6));
        }
    }
});