<template>
    <GalleryTopComponent/>
    <GalleryComponent/>
</template>

<script setup>
    import GalleryTopComponent from '@/components/gallery/GalleryTopComponent.vue';
    import GalleryComponent from '@/components/gallery/GalleryComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Galeri Rs Melinda 2 Bandung',
            description: 'Rs Melinda 2 ini memiliki bangunan 5 lantai dan luas tanah mencapai 3000 meter persegi, untuk mempercantik tata ruang kami memiliki galeri seni yang terdiri dari lukisan dan patung. Jika ingin melihat galeri kami secara virtual dapat dilakukan pada halaman ini.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>